import React, {useEffect, useState} from 'react';
import {Typography, IconButton, ListItem, Tooltip, Menu, MenuItem} from '@mui/material';
import {Box} from '@mui/system';
import CheckBoxInput from '../components/common/CheckBoxInput';
import {Paper} from '@mui/material';
import UserContainer from '../components/common/UserContainer';
import {Link, useNavigate} from 'react-router-dom';
import useAuthStore from '../store/authStore';
import {discipleReadingApi, getUsersDetails, downloadBibleReadingPlan, markChapterReadApi, getMeetings, getBadges} from '../api/index';
import moment from 'moment';
import {FILE_PATH_PROFILE_URL} from '../constants';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {capitalizeWords} from '../utils';
import LaunchIcon from '@mui/icons-material/Launch';
import Loader from '../components/common/Loader';
import DownloadIcon from '@mui/icons-material/Download';
import winnerOne from '../images/winner-one.png';
import winnerTwo from '../images/winner-two.png';
import winnerThree from '../images/winner-three.png';
import goodJobOne from '../images/good-job-one.png';
import goodJobTwo from '../images/good-job-two.png';
import goodJobThree from '../images/good-job-three.png';
import HelpOutline from '@mui/icons-material/HelpOutline';
import Modal from '../components/common/Modal';
import ModalHeader from '../components/common/ModalHeader';
import MarkAttendance from './MarkAttendance';


const Disciple = () => {
  const {user} = useAuthStore(state => state);
  const [bibleData, setBibleData] = useState([]);
  const [bibleReadingData, setBibleReadingData] = useState([]);
  const [outsideReadingData, setOutsideReadingData] = useState(null);
  const [profileData, setProfileData] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [groupId, setGroupId] = useState('');
  const [meeting, setMeeting] = useState();
  const [weeklyReadingLoader, setWeeklyReadingLoader] = useState(false);
  const [lastDate, setLastDate] = useState('');
  const [lastDateForReading, setLastDateForReading] = useState('');
  const [nextMeetingDate, setNextMeetingDate] = useState(null);
  const [prevDate, setPrevDate] = useState(null);
  const [isDropping, setIsDropping] = useState(true);
  const [badgeData, setBadgeData] = useState(null);
  const [badgeLoader, setBadgeLoader] = useState(false);
  const [readMarked, setReadMarked] = useState([]);
  const [fetchGroupId, setfetchGroupId] = useState(null);
  const [fetchGroupDiscipleId, setfetchGroupDiscipleId] = useState(null);
  const [allBadgesStarted, setAllBadgesStarted] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [showMystical, setShowMystical] = useState(false);
  const [noOutSideReading, setNoOutSideReading] = useState(false);
  const [meetingId, setMeetingId] = useState();
  const [currentMeetingDate,setCurrentMeetingForAttendance] = useState(new Date())
  const [isSkipWeek,setIsSkipWeek] = useState(true)
  const getSilverBadgeImage = silverBadge => {
    switch (silverBadge) {
      case 'None':
        return null; // No badge for streak <= 0
      case 'Good_Job_1_Star':
        return goodJobOne;
      case 'Good_Job_2_Stars':
        return goodJobTwo;
      case 'Good_Job_3_Stars':
        return goodJobThree;
      case 'Winner_1_Star':
        return winnerOne;
      case 'Winner_2_Stars':
        return winnerTwo;
      case 'Winner_3_Stars':
        return winnerThree;
      default:
        return null;
    }
  };
  const getGoldBadgeImage = goldBadge => {
    switch (goldBadge) {
      case 'None':
        return null;
      case 'Good_Job_1_Star':
        return goodJobOne;
      case 'Good_Job_2_Stars':
        return goodJobTwo;
      case 'Good_Job_3_Stars':
        return goodJobThree;
      case 'Winner_1_Star':
        return winnerOne;
      case 'Winner_2_Stars':
        return winnerTwo;
      case 'Winner_3_Stars':
        return winnerThree;
      default:
        return null;
    }
  };
  const getPlatinumBadgeImage = platinumBadge => {
    switch (platinumBadge) {
      case 'None':
        return null;
      case 'Good_Job_1_Star':
        return goodJobOne;
      case 'Good_Job_2_Stars':
        return goodJobTwo;
      case 'Good_Job_3_Stars':
        return goodJobThree;
      case 'Winner_1_Star':
        return winnerOne;
      case 'Winner_2_Stars':
        return winnerTwo;
      case 'Winner_3_Stars':
        return winnerThree;
      default:
        return null;
    }
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = type => {
    setAnchorEl(null);
    if (type) {
      downloadPlan(type);
    }
  };

  const getBibleReadingData = async (weekStartDate, type) => {
    try {
      setWeeklyReadingLoader(true);
      const response = await discipleReadingApi(weekStartDate, type);
      if (response.data?.status === 200) {
        const data = response.data;
         if (data?.data?.bible_reading.length > 0) {
          getOutsideReadingData(weekStartDate, 'outside');
          setIsSkipWeek(false)
        } else {
          setOutsideReadingData(null);
          setNoOutSideReading(true);
          setIsSkipWeek(true)
        }
        setMeetingId(data?.data?.group_meetings?.group_weekly_meeting_id);
        setBibleReadingData(data.data);
        setBibleData(prevBibleData => [...prevBibleData, data.data.bible_reading]);
        setNextMeetingDate(data?.data?.group_meetings?.next_meeting_date);
        setLastDateForReading(moment(data?.data?.group_meetings?.last_week_meeting_date).format('YYYY-MM-DD'));
        setLastDate(moment(data?.data?.group_meetings?.next_week_meeting_date));
        setPrevDate(data?.data?.group_meetings?.previous_week_meeting_date);
        setfetchGroupId(data?.data?.group_meetings?.group_id);
        setfetchGroupDiscipleId(data?.data?.discipleId);
        setCurrentMeetingForAttendance(data?.data?.bible_reading[0].date);
       
      }
      setWeeklyReadingLoader(false);
    } catch (error) {
      setWeeklyReadingLoader(false);
    }
  };
  const getOutsideReadingData = async (weekStartDate, type) => {
    try {
      setWeeklyReadingLoader(true);
      const response = await discipleReadingApi(weekStartDate, type);
      if (response.data?.status === 200) {
        const data = response.data;
        setOutsideReadingData(data.data);
         setNoOutSideReading(false);
        if(data.data?.mystical_chapters_book || data.data?.current_mystical_book_name){
          setShowMystical(true)
        }else{
          setShowMystical(false)
        }
        
      }
      setWeeklyReadingLoader(false);
    } catch (error) {
      setWeeklyReadingLoader(false);
    }
  };

  const getBadgeData = async () => {
    try {
      const group_disciples_id = fetchGroupDiscipleId;
      const group_id = fetchGroupId;
      const response = await getBadges(group_disciples_id, group_id);
      if (response.data?.status === 200) {
        const {silverCount, goldCount, platinumCount, totalMeetingWeeks, silverBadge, goldBadge, platinumBadge, totalMeetingAttended} = response.data.data;
        setBadgeData({silverCount, goldCount, platinumCount, totalMeetingWeeks, silverBadge, goldBadge, platinumBadge,totalMeetingAttended});
        const allBadgesStarted = silverBadge == 'None' && goldBadge == 'None' && platinumBadge == 'None';
        if (allBadgesStarted) {
          setAllBadgesStarted(true);
        }
      }
    } catch (error) {
      console.error('Error fetching badge data', error);
    } finally {
      setBadgeLoader(false);
    }
  };

  useEffect(() => {
    if (fetchGroupId && fetchGroupDiscipleId) {
      getBadgeData();
    }
  }, [fetchGroupId, fetchGroupDiscipleId]);

  const getUserHandler = async () => {
    try {
      setLoading(true);
      setBadgeLoader(true);
      const currentUserData = await getUsersDetails(user?.id);
      const response = currentUserData?.data;
      if (response?.status === 200) {
        setBibleData([]);
        setProfileData(prev => ({
          ...prev,
          id: response?.data?.id,
          name: `${response?.data?.first_name} ${response?.data?.last_name === 'undefined' ? '' : response?.data?.last_name}`,
          avatar: response?.data?.avatar !== null ? `${FILE_PATH_PROFILE_URL}/${response?.data?.avatar}` : ''
        }));
        if (response?.data?.disciple_status === 'DROPPING') {
          setIsDropping(true);
        } else {
          setIsDropping(false);
          getGroupMeetingHandler();
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handlePrevious = () => {
    setBibleData([]);
    getBibleReadingData(moment(prevDate).format('YYYY-MM-DDTHH:mm:ss'), 'bible');
  };

  const handleNext = () => {
    setBibleData([]);
    getBibleReadingData(moment(lastDate).add(0, 'day').format('YYYY-MM-DDTHH:mm:ss'), 'bible');
  };

  const downloadPlan = async type => {
    try {
      const response = await downloadBibleReadingPlan(type);
      const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', `bible_reading_plan.${type}`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(blobUrl);
      document.body.removeChild(link);
    } catch (error) {}
  };

  const markChapterReadHandler = async (chapterId, read) => {
    const data = {
      groupId: groupId,
      chapterId: chapterId,
      read: read
    };
    setReadMarked(prev => [...prev, chapterId]);
    try {
      await markChapterReadApi(data);
    } catch (error) {}
  };

  const getGroupMeetingHandler = async () => {
    try {
      const res = await getMeetings('', user.id);
      if (res.data.status === 200) {
        const data = res.data.data;
        setMeeting(data);
        setGroupId(data?.group_id);
        setProfileData(prev => {
          return {
            ...prev,
            groupLevel: data.level
          };
        });
      }
      else if(res.data.status === 204){
        setIsDropping(true);
      }
    } catch (error) {}
  };

  const isPreviousDisabled = () => {
    let daysSubtracted = 7;
    if (meeting.level === 'D1') {
      daysSubtracted = 8;
    }
    return moment(lastDate).subtract(daysSubtracted, 'days').isBefore(moment(meeting?.starting_time));
  };

  useEffect(() => {
    getUserHandler();
  }, []);

  useEffect(() => {
    if (meeting && !isDropping) {
      let today = moment(new Date()).format('YYYY-MM-DD');
      setLastDate(today);
      if (today) {
        getBibleReadingData(today, 'bible');
      }
    }
  }, [meeting, isDropping]);
  function disableNextWeek() {
    if(!lastDate._isValid){
      return true
    }
    if (meeting.level === 'D1') {
      return moment(new Date(lastDate)).startOf('day').subtract('days', 7).format('YYYY-MM-DD') === lastDateForReading;
    } else {
      return moment(new Date(lastDate)).format('YYYY-MM-DD') === lastDateForReading;
    }
  }
  function disablePrevWeek() {
    if (prevDate === null) {
      return true;
    }
    return false;
    // if(meeting.level === 'D1'){
    //   return moment(new Date(lastDate)).startOf('day').subtract('days',7).format("YYYY-MM-DD") === lastDateForReading
    // }else{
    //   return moment(new Date(lastDate)).format("YYYY-MM-DD") === lastDateForReading
    // }
  }

  // const isFirstMeetingDatePastOrCurrent = moment(firstMeetingDate, 'MMMM Do YYYY').isSameOrBefore(moment(), 'day');

  // const getcheckboxMethod =()=>{
  //   const currentDate = new Date();
  //   currentDate.setHours(0, 0, 0, 0);
  //   if(firstMeetingDate){
  //     const currentWeekStartingDate = new Date(firstMeetingDate).setHours(0,0,0,0);
  //     const nextWeekDate = currentWeekStartingDate.moment( )
  //     if(currentDate== date){

  //     }else if () {

  //     }}
  // }
  return (
   
    <UserContainer
      addText="Email Leader"
      onClick={() => {
        navigate('/disciple/email-leader');
      }}
      isShow={!isDropping}
      isShow2={false}
      headingText={profileData?.name}
      filePath={profileData?.avatar}
      headerText={nextMeetingDate && `Your next meeting is ${moment(nextMeetingDate, 'DD-MM-YYYY').format('dddd, MMMM Do')}`}
      showCheckbox={true}
      isDisciple={true}
      fetchGroupDiscipleId={fetchGroupDiscipleId}
      meetingId={meetingId}
      currentMeetingDate={currentMeetingDate}
      previousMeetingDate={prevDate}
      nextMeetingDate={nextMeetingDate}
      isSkipWeek={isSkipWeek}
    >
      {loading ? (
        <Loader />
      ) : isDropping ? (
        <Box display="flex" justifyContent="center" mt={10}>
          <Typography fontSize={18}>Currently you are not in any active group</Typography>
        </Box>
      ) : (
        bibleReadingData && (
          <Box>
            {/* your stats */}
            <Box display="flex" flexDirection="column" alignItems="start" mx={2}>
              <Typography variant="h4">
                Your Stats
                <IconButton
                  mx={2}
                  style={{
                    color: '#fffff'
                  }}
                  onClick={() => setModalOpen(true)}
                >
                  <HelpOutline style={{color: 'white'}} />
                </IconButton>{' '}
              </Typography>
              <hr style={{width: '100%'}} />
            </Box>

            {/* {badgeLoader ? (
              <Loader />
            ) : ( */}
              <Box mt={2} mx={2} display="flex" flexDirection="column" alignItems="start">
                {allBadgesStarted ? (
                  <Typography variant="h5">No Badges Earned!</Typography>
                ) : (
                  badgeData && (
                    <>
                     {/* {badgeData.platinumBadge != 'None' && (
                        <ListItem disablePadding>
                          <IconButton>{badgeData.platinumBadge && <img src={getPlatinumBadgeImage(badgeData?.platinumBadge)} alt="" style={{marginRight: '10px'}} />}</IconButton>
                          <Box display="flex" flexDirection="column" alignItems="start">
                            <Typography variant="h5">Engagement Level - {badgeData?.platinumCount}%.</Typography>
                            <Typography variant="h6">Platinum Level</Typography>
                          </Box>
                        </ListItem>
                      )} */}
                      {badgeData.goldBadge != 'None' && (
                        <ListItem disablePadding>
                          <IconButton>{badgeData.goldBadge && <img src={getGoldBadgeImage(badgeData?.goldBadge)} alt="Gold Badge" style={{marginRight: '10px'}} />}</IconButton>
                          <Box display="flex" flexDirection="column" alignItems="start">
                            <Typography variant="h5">You've Read {badgeData?.totalMeetingAttended} of {badgeData?.totalMeetingWeeks} Weeks</Typography>
                            <Typography variant="h6">Gold Level</Typography>
                          </Box>
                        </ListItem>
                      )}
                       {badgeData.silverBadge != 'None' && (
                        <ListItem disablePadding>
                          <IconButton>{badgeData.silverBadge && <img src={getSilverBadgeImage(badgeData?.silverBadge)} alt="Silver Badge" style={{marginRight: '10px'}} />}</IconButton>
                          <Box display="flex" flexDirection="column" alignItems="start">
                            <Typography variant="h5">
                              Reading Streak - {badgeData?.silverCount} Weeks.
                            </Typography>
                            <Typography variant="h6">Silver Level</Typography>
                          </Box>
                        </ListItem>
                      )}
                     
                    </>
                  )
                )}
              </Box>
            {/* )} */}

            <Box
              display="flex"
              flexDirection="row" // Change this to "row"
              justifyContent="space-between"
              mx={2}
            >
              <Box display="flex" flexDirection="column" alignItems="start" mx={1} mt={3}>
                <Typography variant="h4" textAlign="left">
                  Bible Weekly Reading
                </Typography>
                {/* <Typography variant="body2" fontSize={15} fontWeight={400} mt={1} textAlign="left">
                  By Thursday &bull; Check it off daily to earn badges
                </Typography> */}
              </Box>
              <Tooltip title="Download Options">
                <IconButton aria-label="download" onClick={handleClick}>
                  <DownloadIcon style={{color: 'white',margin: '21px 0 0 0'}} />
                </IconButton>
              </Tooltip>
              <Menu id="download-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => handleClose(null)}>
                <MenuItem
                  sx={{
                    backgroundColor: 'white',
                    color: 'black',
                    '&:hover': {
                      backgroundColor: 'black',
                      color: 'white'
                    }
                  }}
                  onClick={() => handleClose('pdf')}
                >
                  PDF
                </MenuItem>
                <MenuItem
                  sx={{
                    backgroundColor: 'white',
                    color: 'black',
                    '&:hover': {
                      backgroundColor: 'black',
                      color: 'white'
                    }
                  }}
                  onClick={() => handleClose('xlsx')}
                >
                  XLSX
                </MenuItem>
              </Menu>
            </Box>
            <hr style={{width: '95%', margin: '0 10px'}} />
            <Box mx={2} mt={1} color="white" minHeight="275px">
              {weeklyReadingLoader ? (
                <Loader />
              ) : (
                bibleData &&
                bibleData?.map((weekData, weekIndex) => (
                  <React.Fragment key={weekIndex}>
                    {weekData.length === 0 ? (
                      <Box minHeight="275px" display="flex" alignItems="center" justifyContent="center">
                        <Typography>No Bible Reading for this week.</Typography>
                      </Box>
                    ) : (
                      weekData.map((subItem, subIndex) => (
                         <>
                        <Paper elevation={0} key={subIndex}>
                          <Box
                            gap={1}
                            color="white"
                            mt={1}
                            sx={{
                              display: 'flex',
                              alignItems: 'flex-start',
                              flexDirection: 'column'
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignContent: 'left',
                                textAlign: 'left'
                              }}
                              key={subIndex}
                            >
                              <CheckBoxInput defaultValue={subItem.read} disabled={subItem.read || readMarked.includes(subItem?.id)} type="checkbox" id={`week-${weekIndex}-${subIndex}`} onChange={value => markChapterReadHandler(subItem?.id, value)} />
                              <label style={{alignContent: 'center'}} htmlFor={`week-${weekIndex}-${subIndex}`}>
                                {moment(subItem.date, 'MMMM Do YYYY').format('dddd, MMMM Do')} &bull; {subItem.chapters}
                              </label>
                            </Box>
                          </Box>
                        </Paper>
                    
            
            
                        </>
                      ))
                    )}
                     <hr style={{width: '100%', marginTop: '10px'}} />
                     <MarkAttendance fetchGroupDiscipleId={fetchGroupDiscipleId}
              meetingId={meetingId} currentMeetingDate={currentMeetingDate}
              previousMeetingDate={prevDate} nextMeetingDate={lastDate} 
              isSkipWeek={isSkipWeek}/>
                    <Box my={2} display="flex" gap={2} justifyContent="center">
                      <IconButton
                        onClick={handlePrevious}
                        style={{
                          fontSize: '14px',
                          color: prevDate === null ? 'lightgray' : 'white'
                        }}
                        disabled={prevDate === null}
                      >
                        <ArrowBackIosIcon /> Previous Week
                      </IconButton>
                      <IconButton style={{fontSize: '14px', color: disableNextWeek() ? 'lightgray' : 'white'}} onClick={handleNext} disabled={disableNextWeek()}>
                        Next Week <ArrowForwardIosIcon />
                      </IconButton>
                    </Box>
                  </React.Fragment>
                ))
              )}
            </Box>

            {/* outside reading */}
            <Box display="flex" flexDirection="column" alignItems="start" mx={2} mt={2}>
              <Typography variant="h4" textAlign="left">
                Outside Weekly Reading
              </Typography>
              <hr style={{width: '100%'}} />
            </Box>
            {noOutSideReading &&  <Typography>No Outside Reading for this week.</Typography>}
             {outsideReadingData?.outside_reading?.current_book?.split('|')?.map(book => {
                    return (
                      <Box mt={1}  mx={2} display="flex" flexDirection="row" alignItems="center">
              <Typography variant="h2" color="white" fontSize={20} fontWeight={400} marginRight={1}>
                {!book ? 'No outside reading for this week.' :
                <Link to="https://disciples.sustainable-discipleship.com" target="_blank">
                     <Typography variant="h2" color="white" fontSize={20} fontWeight={400} marginRight={1}> {'Continue ' + book}</Typography>
                  </Link>}

              </Typography>
             { !book ? '' : <Link to="https://disciples.sustainable-discipleship.com" target="_blank">
                      <LaunchIcon sx={{color: 'white'}} />
                  </Link>}
            </Box>
                    );
                  })}
            {/* mystical reading */}
            {showMystical && (
              <>
                <Box display="flex" flexDirection="column" alignItems="start" mx={2} mt={2}>
                  <Typography variant="h4" textAlign="left">
                    Mystical Chapter
                  </Typography>
                  <hr style={{width: '100%'}} />
                </Box>
                <Box mt={1} mx={2} display="flex" flexDirection="column" alignItems="start">
                  {outsideReadingData?.mystical_chapters_book && <Typography variant="h2" color="white" fontSize={20} fontWeight={400} textAlign="left">
                    {'Week First Day Reading ' + outsideReadingData.mystical_chapters_book}
                  </Typography>}
                   {outsideReadingData?.current_mystical_book_name && <Typography variant="h2" color="white" fontSize={20} fontWeight={400} textAlign="left">
                    {`Today's Reading - ${outsideReadingData.current_mystical_book_name}`}
                  </Typography>}
                </Box>
              </>
            )}
            {/* time to order */}
            {outsideReadingData?.outside_reading?.next_book && (
              <>
                <Box display="flex" flexDirection="column" alignItems="start" mx={2} mt={3}>
                  <Typography variant="h4" fontWeight={600}>
                    It's Time to Order!
                  </Typography>
                  <hr style={{width: '100%'}} />
                </Box>
                {outsideReadingData?.outside_reading?.next_book?.split('|')?.map(book => {
                  return (
                    <Box mt={1} mx={2} mb={1} display="flex" alignItems="start">
                      <Typography variant="h2" color="white" fontSize={20} fontWeight={400} display="flex" marginRight={1} gap={1}>
                        {book}
                      </Typography>
                    {  <Link to="https://disciples.sustainable-discipleship.com" target="_blank">
                        <LaunchIcon sx={{color: 'white'}} />
                      </Link>}
                    </Box>
                  );
                })}
              </>
            )}
            {/* Modal implementation */}
            <Modal open={modalOpen} setOpen={setModalOpen} buttons={[{name: 'Close', onClick: () => setModalOpen(false)}]} style={{height: '100%'}} backgroundColor="white">
              <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} textAlign={'justify'} p={1}>
                <ModalHeader title="Two Badges <br/> Three Stars Each" />
                <Typography variant="h7" fontWeight="bold" mt={1} color="#000">
                  PLATINUM LEVEL - ENGAGEMENT
                </Typography>
                <Typography color="#000">This is the most difficult level. Being there and being prepared will allow you to get the most out of discipleship. Your engagement score is your Overall Reading Percentage times Total Meetings Attended divided by Total Meetings.</Typography>
                <Box display={'flex'} gap={2} mb={1}>
                  <Box>
                    <img src={winnerOne} alt="img" />
                    <Typography mt={1} color="#000">
                      ONE STAR = 95%
                    </Typography>
                    <Typography color="#000">TWO STAR = 97%</Typography>
                    <Typography color="#000">THREE STAR = 100%</Typography>
                  </Box>
                  <Box>
                    <img src={goodJobOne} alt="img" />
                    <Typography mt={1} color="#000">
                      ONE STAR = 50%
                    </Typography>
                    <Typography color="#000">TWO STAR = 90%</Typography>
                    <Typography color="#000">THREE STAR = 93%</Typography>
                  </Box>
                </Box>
                <Typography variant="h7" fontWeight="bold" mt={1} color="#000">
                  GOLD LEVEL - OVERALL READING
                </Typography>
                <Typography color="#000">A Percentage of how many weeks you have read.</Typography>
                <Box display={'flex'} gap={2} mb={1}>
                  <Box>
                    <img src={winnerOne} alt="img" />
                    <Typography mt={1} color="#000">
                      ONE STAR = 95%
                    </Typography>
                    <Typography color="#000">TWO STAR = 97%</Typography>
                    <Typography color="#000">THREE STAR = 100%</Typography>
                  </Box>
                  <Box>
                    <img src={goodJobOne} alt="img" />
                    <Typography mt={1} color="#000">
                      ONE STAR = 50%
                    </Typography>
                    <Typography color="#000">TWO STAR = 90%</Typography>
                    <Typography color="#000">THREE STAR = 93%</Typography>
                  </Box>
                </Box>
                <Typography variant="h7" fontWeight="bold" mt={1} color="#000">
                  SILVER LEVEL - READING STREAK
                </Typography>
                <Typography color="#000">How many weeks you have read in a row.</Typography>
                <Box display={'flex'} gap={2}>
                  <Box>
                    <img src={winnerOne} alt="img" />
                    <Typography mt={1} color="#000">
                      ONE STAR &gt;20
                    </Typography>
                    <Typography color="#000">TWO STAR &gt;30</Typography>
                    <Typography color="#000">THREE STAR&gt;40</Typography>
                  </Box>
                  <Box>
                    <img src={goodJobOne} alt="img" />
                    <Typography mt={1} color="#000">
                      ONE STAR &lt;5
                    </Typography>
                    <Typography color="#000">TWO STAR &gt;5</Typography>
                    <Typography color="#000">THREE STAR &gt;10</Typography>
                  </Box>
                </Box>
              </Box>
            </Modal>
          </Box>
        )
      )}
    </UserContainer>
  );
};

export default Disciple;
